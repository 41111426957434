import Vue from 'vue';
import VueRouter from 'vue-router';
import PatientMain from '../views/PatientMain.vue';
import NotFound from '../views/NotFound.vue';
// import Home from '../views/Home.vue';
import PatientVerification from '../views/PatientVerification.vue';
import { coreServices } from '../services/coreService';

Vue.use(VueRouter);

async function checkValidatedUser(to, from, next) {
  const email = to.params.userId;
  const userDoc = await coreServices.checkEmail(email);
  if (userDoc.verificated_email === false) {
    next();
  } else {
    next(`/${to.params.trialId}`);
  }
}

const routes = [
  {
    path: '/trial/:trialId',
    name: 'PatientMain',
    component: PatientMain,
  },
  {
    path: '/trial/:trialId/:userId',
    name: 'PatientVerification',
    component: PatientVerification,
    beforeEnter: checkValidatedUser,
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});
router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next('/404');
  } else {
    next();
  }
});
export default router;
