import { coreServices } from './coreService';

/* eslint-disable-next-line */
export const trialServices = {
  getTrialInformation(trialId) {
    try {
      const result = coreServices.getTrial(trialId);
      return result;
    } catch (err) {
      return [];
    }
  },
  saveRejectedTrialInformation(patientData) {
    try {
      let formAnswered = '';
      for (let i = 0; i < patientData.questions.length; i += 1) {
        const question = patientData.questions[i];
        const answer = patientData.question_answered[i].option;
        formAnswered += `${question}:  ${answer}`;
        if (i < patientData.questions.length - 1) {
          formAnswered += ', ';
        }
      }
      const result = coreServices.saveRejectedTrial({
        name: patientData.name,
        surname: patientData.surname,
        email: patientData.email,
        age: patientData.age,
        gender: patientData.gender,
        phone: patientData.phone,
        trial_id: patientData.trial_id,
        trial_name: patientData.trial_name,
        created_at: new Date(),
        formAnswered,
      });
      return result;
    } catch (err) {
      return [];
    }
  },
  saveTrialInformation(trialInformation) {
    try {
      /* eslint-disable no-param-reassign */
      trialInformation.created_at = new Date();
      delete trialInformation.prefix;
      trialInformation.form_answer = trialInformation.question_answered;
      delete trialInformation.question_answered;
      coreServices.saveTrial(trialInformation);
      return 'User added';
    } catch (err) {
      return [];
    }
  },
  checkIfEmailExists(email, trialId) {
    try {
      const result = coreServices.checkTrialEmail(email, trialId);
      return result;
    } catch (err) {
      return err;
    }
  },
};
