<template>
  <v-container fluid class="main-container">
    <div id="rotate-to-portrait">
      <span class="rotate-to-portrait-text">{{ $t('trial-screen.rotate') }}</span>
    </div>
    <v-row class="main-screen">
      <v-col cols="12" md="6" class="my-0 py-0">
        <v-img
          src="../assets/images/hospital.png"
          class="responsive-image"
          aspect-ratio="1.6"
          :contain="false"
        />
      </v-col>
      <v-col cols="12" md="6" class="form-side">
        <v-row>
          <v-col cols="12" sm="6" class="language-icons">
            <locale-switcher />
          </v-col>
          <v-col cols="12" sm="6">
            <v-img
              class="sermes-logo"
              :src="require('../assets/images/patient_up_logo.png')"
              :contain="true" />
          </v-col>
        </v-row>
        <v-row class="trial-content">
          <v-col cols="12" class="pb-0 mx-0 px-0 my-0 py-0">
            <v-card id="hospital-form-card" class="form-card">
              <v-card-title primary-title class="justify-left mt-0 pt-0 pl-0">
                <h3 class="step-title">
                  <span id="hospital-form-title">
                    {{ $t('hospital-screen.title') }}
                  </span>
                </h3>
              </v-card-title>
              <v-card-text class="hospital-content">
                <v-form id="hospital-form" ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="8" md="8" lg="8" xl="8" class="mx-auto">
                      <v-select
                        id="community"
                        class="select-community"
                        v-model="selectedCommunity"
                        :items="communities"
                        :label="$t('hospital-screen.select-community')"
                        required
                        :error="showErrors.community"
                      />  
                      <v-select
                        id="hospital"
                        class="select-hospital"
                        v-model="hospitalSelected"
                        :items="filteredHospitals"
                        item-text="name"
                        :label="$t('hospital-screen.select-hospital')"
                        return-object
                        required
                        :disabled="!selectedCommunity"
                        :error="showErrors.hospital"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions class="hospital-buttons justify-center">
                <v-btn
                  id="hospital-form-finish-button"
                  class="hospital-button"
                  :class="checkInvalidForm ? 'button-disabled' : 'button-enabled'"
                  @click="finish()"
                >{{ $t('hospital-screen.button-finish') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics';
import LocaleSwitcher from './LocaleSwitcher.vue';
import getFirebaseConfig from '../utils/firebaseConfig';

export default {
  name: 'HospitalForm',
  components: {
    LocaleSwitcher,
  },
  props: {
    hospitals: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      icons: ['mdi-information ', 'mdi-account', 'mdi-medical-bag', '', 'mdi-doctor'],
      valid: false,
      step: 5,
      selectedCommunity: null,
      all_hospitals: {},
      hospitalNames: {},
      hospitalSelected: {},
      showErrors: {
        community: false,
        hospital: false,
      },
    };
  },
  created() {
    fetch('/hospitals_location.json')
      .then((response) => response.json())
      .then((data) => {
        this.all_hospitals = data;
        this.hospitalNames = this.hospitals.map((hospital) => hospital.center_name.toLowerCase());
      });
    this.addEvent('page_view', 'Hospital Form view');
  },
  computed: {
    communities() {
      const hospitalCCAA = Object.entries(this.all_hospitals)
        .filter(([key]) => this.hospitalNames.includes(key.toLowerCase()))
        .map(([, value]) => value.CCAA);
      return [...new Set(hospitalCCAA)];
    },
    filteredHospitals() {
      if (!this.selectedCommunity) {
        return [];
      }
      const hospitalsInSelectedCCAA = Object.entries(this.all_hospitals)
        .filter(([key, value]) => this.hospitalNames.includes(key.toLowerCase()) 
          && value.CCAA === this.selectedCommunity)
        .map(([key, value]) => ({
          name: key,
          municipio: value.municipio,
          comunidad_autonoma: value.CCAA,
        }));
      return hospitalsInSelectedCCAA;
    },
    checkInvalidForm() {
      return !this.selectedCommunity || Object.keys(this.hospitalSelected).length === 0;
    },
    form() {
      return [
        this.$t('trial-screen.title'),
        this.$t('patient-screen.title'),
        this.$t('questionary-screen.title'),
        '', 
        this.$t('hospital-screen.title'),
      ];
    },
  },
  methods: {
    async addEvent(eventName, passName) {
      if (await isSupported()) {
        const app = initializeApp(getFirebaseConfig());
        const analytics = getAnalytics(app);
        logEvent(analytics, eventName, { name: eventName, arguments: passName });
      }
    },
    finish() {
      if (!this.checkInvalidForm) {
        this.step += 1;
        this.$emit('update', {
          step: this.step,
          hospitalSelected: this.hospitalSelected,
        });
        this.addEvent('step5_finish_button', 'Step5 Finish Button');
      } else {
        this.showErrors.community = !this.selectedCommunity;
        this.showErrors.hospital = Object.keys(this.hospitalSelected).length === 0;
      }
    },
  },
  watch: {
    selectedCommunity(newVal) {
      if (newVal) {
        this.hospitalSelected = {};
        this.showErrors.hospital = true;
        this.showErrors.community = false;
      }
    },
    hospitalSelected(newVal) {
      if (Object.keys(newVal).length > 0) {
        this.showErrors.hospital = false;
      }
    },
  },
};
</script>
<style scoped>
#rotate-to-portrait {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(249, 254, 254, 1);
  color: var(--highlightedPU);
  text-align: center;
  z-index: 10000; /* Asegura que se muestra sobre todo lo demás */
  align-items: center;
  justify-content: center;
}
.rotate-to-portrait-text {
  width: 70%;
  line-height: 2em;
  font-size: 1.4em;
  font-weight: bold;
}
.responsive-image {
  height: 100vh;
}
.sermes-logo {
  height: 12vh;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}
.form-side {
  width: 50%;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: none;
  position: fixed;
  top: 0;
  right: 0;
}
.form-card {
  margin: 0;
  padding: 0;
  box-shadow: none!important;
}
.step-title {
  margin-top: 0;
  font-family: 'Roboto-Regular';
  margin-left: 15%;
  font-size: 1.3em;
  background: linear-gradient(90deg, #13c5e0, #2e90e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.form-card {
  margin: 0;
  padding: 0;
  box-shadow: none!important;
}
.hospital-content {
    margin: auto;
    margin-top: 15vh;
    height: 35vh;
    width: 85%;
    position: relative;
}
.hospital-buttons {
  margin-top: 3vh;
}
.hospital-button {
  margin-top: 0;
  margin-bottom: 2vh;
  text-align: center;
  width: 200px;
  height: 52px!important;
  font-family: 'Roboto-Bold';
  font-size: 0.9em;
  border-radius: 35px;
  background: none!important;
  border: solid var(--highlightedPU) 3px!important;
  color: var(--highlightedPU)!important;
  opacity: 1;
  box-shadow: none!important;
}
#hospital-form-finish-button {
  font-family: 'Roboto-Bold';
  font-size: 1.1em;
  background: none!important;
  box-shadow: none!important;;
}
.button-enabled {
  border: solid var(--highlightedPU) 3px;
  color: var(--highlightedPU);
  opacity: 1;
}
.button-disabled {
  cursor: not-allowed! important;
  border: solid var(--nonHighlightedTextPU) 3px;
  color: var(--nonHighlightedTextPU);
  opacity: 0.45;
}

@media (min-width: 2561px) {
  .main-screen {
    height: 100vh;
  }
  .responsive-image {
    height: 100vh!important;
  }
  .language-icons {
    padding-top: 0;
    margin-top: 10px!important;
    margin-left: -30px;
  }
  .sermes-logo {
    height: 10vh;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .form-side {
    width: 100%;
    margin-top: 5vh;
    height: 60vh;
    padding: 0;
    top: 0;
    right: 0;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .step-title {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20%;
    font-size: 1.1em;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .hospital-content {
      margin: auto;
      margin-top: -20px;
      height: 35vh;
      width: 85%;
      position: relative;
  }
  .hospital-buttons {
    margin-top: -70px;
  }
  .hospital-button {
    margin-top: 0;
    margin-bottom: 2vh;
    text-align: center;
    width: 25%;
    height: 47px!important;
    font-size: 0.9em!important;
  }
  #hospital-form-finish-button {
    font-size: 1.1em;
  }
}
@media (min-width: 1441px) and (max-width: 2561px) {
  .main-screen {
    height: 100vh;
  }
  .responsive-image {
    height: 100vh!important;
  }
  .language-icons {
    padding-top: 0;
    margin-top: 10px!important;
    margin-left: -30px;
  }
  .sermes-logo {
    height: 10vh;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .form-side {
    width: 100%;
    margin-top: 5vh;
    height: 60vh;
    padding: 0;
    top: 0;
    right: 0;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .step-title {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20%;
    font-size: 1.1em;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .hospital-content {
    margin: auto;
    margin-top: -20px;
    height: 35vh;
    width: 85%;
    position: relative;
  }
  .hospital-buttons {
    margin-top: -70px;
  }
  .hospital-button {
    margin-top: 2vh;
    margin-bottom: 2vh;
    text-align: center;
    width: 23%;
    height: 45px!important;
    font-size: 1em!important;
  }
  #hospital-form-finish-button {
    font-size: 1.1em;
  }
}
@media (min-width: 1365px) and (max-width: 1440px) {
  .main-screen {
    height: 100vh;
  }
  .responsive-image {
    height: 100vh!important;
  }
  .language-icons {
    padding-top: 0;
    margin-top: 10px!important;
    margin-left: -30px;
  }
  .sermes-logo {
    height: 10vh;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .form-side {
    width: 100%;
    margin-top: 5vh;
    height: 60vh;
    padding: 0;
    top: 0;
    right: 0;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .step-title {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20%;
    font-size: 1.1em;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .hospital-content {
      margin: auto;
      margin-top: -20px;
      height: 35vh;
      width: 85%;
      position: relative;
  }
  .hospital-buttons {
    margin-top: -70px;
  }
  .hospital-button {
    margin-top: 0;
    margin-bottom: 2vh;
    text-align: center;
    width: 25%;
    height: 47px!important;
    font-size: 0.9em!important;
  }
  #hospital-form-finish-button {
    font-size: 1.1em;
  }
}
@media (min-width: 1281px) and (max-width: 1365px) {
  .main-screen {
    height: 100vh;
  }
  .responsive-image {
    height: 100vh!important;
  }
  .language-icons {
    padding-top: 0;
    margin-top: 10px!important;
    margin-left: -30px;
  }
  .sermes-logo {
    height: 10vh;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .form-side {
    width: 100%;
    margin-top: 5vh;
    height: 60vh;
    padding: 0;
    top: 0;
    right: 0;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .step-title {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20%;
    font-size: 1.1em;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .hospital-content {
      margin: auto;
      margin-top: -20px;
      height: 35vh;
      width: 85%;
      position: relative;
  }
  .hospital-buttons {
    margin-top: -70px;
  }
  .hospital-button {
    margin-top: 0;
    margin-bottom: 2vh;
    text-align: center;
    width: 25%;
    height: 47px!important;
    font-size: 0.9em!important;
  }
  #hospital-form-finish-button {
    font-size: 1.1em;
  }
}
@media (min-width: 1180px) and (max-width: 1280px) {
  .main-screen {
    height: 100vh;
  }
  .responsive-image {
    height: 100vh!important;
  }
  .language-icons {
    padding-top: 0;
    margin-top: 10px!important;
    margin-left: -30px;
  }
  .sermes-logo {
    height: 10vh;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .form-side {
    width: 100%;
    margin-top: 5vh;
    height: 60vh;
    padding: 0;
    top: 0;
    right: 0;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .step-title {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20%;
    font-size: 1.1em;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .hospital-content {
      margin: auto;
      margin-top: -20px;
      height: 35vh;
      width: 85%;
      position: relative;
  }
  .hospital-buttons {
    margin-top: -70px;
  }
  .hospital-button {
    margin-top: 0;
    margin-bottom: 2vh;
    text-align: center;
    width: 25%;
    height: 47px!important;
    font-size: 0.9em!important;
  }
  #hospital-form-finish-button {
    font-size: 1.1em;
  }
}
@media (min-width: 1025px) and (max-width: 1179px) {
  .main-screen {
    height: 100vh;
  }
  .responsive-image {
    height: 100vh!important;
  }
  .language-icons {
    padding-top: 0;
    margin-top: 10px!important;
    margin-left: -30px;
  }
  .sermes-logo {
    height: 10vh;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .form-side {
    width: 100%;
    margin-top: 5vh;
    height: 60vh;
    padding: 0;
    top: 0;
    right: 0;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .step-title {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20%;
    font-size: 1.1em;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .hospital-content {
      margin: auto;
      margin-top: -20px;
      height: 35vh;
      width: 85%;
      position: relative;
  }
  .hospital-buttons {
    margin-top: -70px;
  }
  .hospital-button {
    margin-top: 0;
    margin-bottom: 2vh;
    text-align: center;
    width: 25%;
    height: 47px!important;
    font-size: 0.9em!important;
  }
  #hospital-form-finish-button {
    font-size: 1.1em;
  }
}
@media (min-width: 960px) and (max-width: 1024px) {
  .main-screen {
    height: 100vh;
  }
  .responsive-image {
    height: 100vh!important;
  }
  .language-icons {
    padding-top: 0;
    margin-top: 10px!important;
    margin-left: -30px;
  }
  .sermes-logo {
    height: 10vh;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .form-side {
    width: 100%;
    margin-top: 5vh;
    height: 60vh;
    padding: 0;
    top: 0;
    right: 0;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .step-title {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20%;
    font-size: 1.1em;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .hospital-content {
      margin: auto;
      margin-top: -20px;
      height: 35vh;
      width: 85%;
      position: relative;
  }
  .hospital-buttons {
    margin-top: -70px;
  }
  .hospital-button {
    margin-top: 0;
    margin-bottom: 2vh;
    text-align: center;
    width: 25%;
    height: 47px!important;
    font-size: 0.9em!important;
  }
  #hospital-form-finish-button {
    font-size: 1.1em;
  }
}
@media (min-width: 821px) and (max-width: 959px) {
  .main-screen {
    height: 100vh;
  }
  .responsive-image {
    height: 20vh;
  }
  .language-icons {
    padding-top: 0;
    margin-top: 10px!important;
    margin-left: -30px;
  }
  .sermes-logo {
    height: 10vh;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .form-side {
    width: 100%;
    margin-top: 20vh;
    height: 60vh;
    padding: 0;
    top: 0;
    right: 0;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .step-title {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20%;
    font-size: 1.1em;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .hospital-content {
      margin: auto;
      margin-top: -20px;
      height: 35vh;
      width: 85%;
      position: relative;
  }
  .hospital-buttons {
    margin-top: 20px;
  }
  .hospital-button {
    margin-top: 0;
    margin-bottom: 2vh;
    text-align: center;
    width: 25%;
    height: 47px!important;
    font-size: 0.9em!important;
  }
  #hospital-form-finish-button {
    font-size: 1.1em;
  }
}
@media (min-width: 769px) and (max-width: 820px) {
  .main-screen {
    height: 100vh;
  }
  .responsive-image {
    height: 20vh;
  }
  .language-icons {
    padding-top: 0;
    margin-top: 10px!important;
    margin-left: -30px;
  }
  .sermes-logo {
    height: 10vh;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .form-side {
    width: 100%;
    margin-top: 20vh;
    height: 60vh;
    padding: 0;
    top: 0;
    right: 0;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .step-title {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20%;
    font-size: 1.1em;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .hospital-content {
      margin: auto;
      margin-top: -20px;
      height: 35vh;
      width: 85%;
      position: relative;
  }
  .hospital-buttons {
    margin-top: 20px;
  }
  .hospital-button {
    margin-top: 0;
    margin-bottom: 2vh;
    text-align: center;
    width: 25%;
    height: 47px!important;
    font-size: 0.9em!important;
  }
  #hospital-form-finish-button {
    font-size: 1.1em;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .main-screen {
    height: 100vh;
  }
  .responsive-image {
    height: 20vh;
  }
  .language-icons {
    padding-top: 0;
    margin-top: 10px!important;
    margin-left: -30px;
  }
  .sermes-logo {
    height: 10vh;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .form-side {
    width: 100%;
    margin-top: 20vh;
    height: 60vh;
    padding: 0;
    top: 0;
    right: 0;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .step-title {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 55px;
    font-size: 1em;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .hospital-content {
      margin: auto;
      margin-top: -20px;
      height: 35vh;
      width: 85%;
      position: relative;
  }
  .hospital-buttons {
    margin-top: 20px;
  }
  .hospital-button {
    margin-top: 0;
    margin-bottom: 2vh;
    width: 29%;
    height: 45px!important;
    font-size: 0.9em!important;
  }
  #hospital-form-finish-button {
    font-size: 1.1em;
  }
}
@media (min-width: 380px) and (max-width: 480px) {
  .main-screen {
    height: 100vh;
  }
  .responsive-image {
    height: 20vh;
  }
  .language-icons {
    padding-top: 0;
    margin-top: 10px!important;
    margin-left: -30px;
  }
  .sermes-logo {
    height: 10vh;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .form-side {
    width: 100%;
    margin-top: 20vh;
    height: 60vh;
    padding: 0;
    top: 0;
    right: 0;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .step-title {
    margin-top: 0;
    margin-left: 15%;
    font-size: 1em;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .hospital-content {
      margin: auto;
      margin-top: 2vh;
      height: 35vh;
      width: 85%;
      position: relative;
  }
  .hospital-buttons {
    margin-top: 1vh;
  }
  .hospital-button {
    margin-top: -20px;
    text-align: center;
    width: 40%;
    height: 43px!important;
    font-size: 0.9em;
  }
  #hospital-form-finish-button {
    font-size: 1em;
  }
}
@media (min-width: 281px) and (max-width: 379px) {
  .main-screen {
    height: 100vh;
  }
  .responsive-image {
    height: 20vh;
  }
  .language-icons {
    padding-top: 0;
    margin-top: 10px!important;
    margin-left: -30px;
  }
  .sermes-logo {
    height: 10vh;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .form-side {
    width: 100%;
    margin-top: 20vh;
    height: 60vh;
    padding: 0;
    top: 0;
    right: 0;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .step-title {
    margin-top: 0;
    margin-left: 15%;
    font-size: 1em;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .hospital-content {
      margin: auto;
      margin-top: 2vh;
      height: 35vh;
      width: 85%;
      position: relative;
  }
  .hospital-buttons {
    margin-top: 1vh;
  }
  .hospital-button {
    margin-top: 0;
    margin-bottom: 2vh;
    text-align: center;
    width: 45%;
    height: 43px!important;
    font-size: 0.9em;
  }
  #hospital-form-finish-button {
    font-family: 'Roboto-Bold';
    font-size: 1.1em;
    background: none!important;
    box-shadow: none!important;;
  }
  .button-enabled {
    border: solid var(--highlightedPU) 3px;
    color: var(--highlightedPU);
    opacity: 1;
  }
  .button-disabled {
    cursor: not-allowed! important;
    border: solid var(--nonHighlightedTextPU) 3px;
    color: var(--nonHighlightedTextPU);
    opacity: 0.45;
  }
}
@media (max-width: 280px) {
  .main-screen {
    height: 100vh;
  }
  .responsive-image {
    height: 20vh;
  }
  .language-icons {
    padding-top: 0;
    margin-top: 10px!important;
    margin-left: -30px;
  }
  .sermes-logo {
    height: 10vh;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .form-side {
    width: 100%;
    margin-top: 20vh;
    height: 60vh;
    padding: 0;
    top: 0;
    right: 0;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .step-title {
    margin-top: 0;
    margin-left: 15%;
    font-size: 1em;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .hospital-content {
      margin: auto;
      margin-top: 2vh;
      height: 35vh;
      width: 85%;
      position: relative;
  }
  .hospital-buttons {
    margin-top: 1vh;
  }
  .hospital-button {
    margin-top: 0;
    margin-bottom: 2vh;
    text-align: center;
    width: 60%;
    height: 43px!important;
    font-size: 0.9em;
  }
  #hospital-form-finish-button {
    font-family: 'Roboto-Bold';
    font-size: 1.1em;
    background: none!important;
    box-shadow: none!important;;
  }
  .button-enabled {
    border: solid var(--highlightedPU) 3px;
    color: var(--highlightedPU);
    opacity: 1;
  }
  .button-disabled {
    cursor: not-allowed! important;
    border: solid var(--nonHighlightedTextPU) 3px;
    color: var(--nonHighlightedTextPU);
    opacity: 0.45;
  }
}

@media screen and (min-resolution: 2dppx) and (orientation: portrait) and (max-width: 1250px) {
  .main-screen {
    height: 100vh;
  }
  .responsive-image {
    position: fixed;
    width: 100%;
    height: 20vh;
  }
  .language-icons {
    padding-top: 0;
    margin-top: 1vh!important;
    margin-left: -30px;
  }
  .sermes-logo {
    width: 100%;
    margin-top: -1vh!important;
    height: 10vh;
    margin: 0 auto;
    padding: 0;    
  }
  .form-side {
    width: 100%;
    margin-top: 20vh;
    height: 60vh;
    padding: 0;
    top: 0;
    right: 0;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .step-title {
    margin-top: 0vh;
    margin-left: 12%;
    margin-bottom: 2vh;
    text-align: left;
    width: 100%;
    font-size: 1.2em!important;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .hospital-content {
      margin: auto;
      margin-top: -20px;
      height: 35vh;
      width: 85%;
      position: relative;
  }
  .hospital-buttons {
    margin-top: 20px;
  }
  .hospital-button {
    margin-top: -5vh;
    margin-bottom: 2vh;
    width: 35%;
    height: 45px!important;
    font-size:1em!important;
  }
  #hospital-form-finish-button {
    font-size: 1.1em;
  }
}
@media screen and (min-resolution: 2dppx) and (orientation: portrait) and (max-width: 375px) {
  .main-screen {
    height: 100vh;
  }
  .responsive-image {
    position: fixed;
    width: 100%;
    height: 20vh;
  }
  .language-icons {
    padding-top: 0;
    margin-top: 1vh!important;
    margin-left: -30px;
  }
  .sermes-logo {
    width: 100%;
    margin-top: -1vh!important;
    height: 10vh;
    margin: 0 auto;
    padding: 0;    
  }
  .form-side {
    width: 100%;
    margin-top: 20vh;
    height: 60vh;
    padding: 0;
    top: 0;
    right: 0;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .step-title {
    margin-top: 0vh;
    margin-left: 12%;
    margin-bottom: 2vh;
    text-align: left;
    width: 100%;
    font-size: 1.2em!important;
  }
  .form-card {
    margin: 0;
    padding: 0;
    box-shadow: none!important;
  }
  .hospital-content {
      margin: auto;
      margin-top: -20px;
      height: 35vh;
      width: 85%;
      position: relative;
  }
  .hospital-buttons {
    margin-top: 20px;
  }
  .hospital-button {
    margin-top: -5vh;
    margin-bottom: 2vh;
    width: 35%;
    height: 45px!important;
    font-size:1em!important;
  }
  #hospital-form-finish-button {
    font-size: 1.1em;
  }
}

/* Estilos para móviles en landscape */
  @media screen and (max-width: 1023px) and (orientation: landscape) {
    #rotate-to-portrait {
        display: flex;
    }
  }
</style>
