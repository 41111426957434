<template>
  <main-form />
</template>

<script>
import MainForm from '../components/MainForm.vue';

export default {
  name: 'PatientMain',

  components: {
    MainForm,
  },
};
</script>
<style>
</style>
