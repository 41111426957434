<template>
  <v-app style="overflow: hidden;">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

import imageLogo from './assets/images/patient_up_logo.png';
import imageScreen1 from './assets/images/patient3.png';
import imageScreen2 from './assets/images/04.png';
import imageScreen3 from './assets/images/pantalla_3.png';
import imageScreen4 from './assets/images/hospital.png';

const imageUrls = [
  imageLogo,
  imageScreen1,
  imageScreen2,
  imageScreen3,
  imageScreen4,
];

export default {
  created() {
    this.$store.dispatch('setTotalImages', imageUrls.length);
    setTimeout(() => {
      this.preloadImages();
    }, 700);
  },
  methods: {
    preloadImages() {
      imageUrls.forEach((imageUrl) => {
        const img = new Image();
        img.onload = () => {
          this.$store.dispatch('incrementLoadedImagesCount');
        };
        img.src = imageUrl;
      });
    },
  },
};
</script>
<style>
</style>
