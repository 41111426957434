export default function getFirebaseConfig() {
  return {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: `${process.env.VUE_APP_PROJECT_ID}.firebaseapp.com`,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: `${process.env.VUE_APP_PROJECT_ID}.appspot.com`,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
    trialCollectionName: process.env.VUE_APP_TRIAL_COLLECTION_NAME,
    userCollectionName: process.env.VUE_APP_USER_COLLECTION_NAME,
    userRejectedCollectionName: process.env.VUE_APP_USER_REJECTED_COLLECTION_NAME,
    emailCollectionName: process.env.VUE_APP_EMAIL_COLLECTION_NAME,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID,
    databaseId: process.env.VUE_APP_FIREBASE_DATABASE_NAME,
  };
}
