import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        blueDarkPU: '#2e90e0',
        blueLightPU: '#13c5e0',
        typographyPU: '#575757',
        highlightedPU: '#1db9d9',
        nonHighlightedTextPU: '#8d8d8d',
        redPU: '#d02222',
        greenPU: '#3ec120',
      },
    },
  },
  options: {
    customProperties: true,
  },
});
