<template lang="">
  <v-app id="inspire">
    <v-container style="margin-top:100px;">
      <v-row>
        <v-col cols="12" sm="10" md="10" lg="10" xl="10" class="mx-auto">
          <template>
            <div>
              <v-card>
                <v-card-title>
                  <span class="headline">
                    <v-icon>mdi-check-circle</v-icon>
                    <span class="title">
                      {{ $t('verification-screen.title') }}
                    </span>
                  </span>
                </v-card-title>
                <v-card-text>
                  <p> {{ $t('verification-screen.msg') }}</p>
                </v-card-text>
              </v-card>
            </div>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import { coreServices } from '../services/coreService';

export default {
  name: 'PatientVerification',
  components: {
  },
  data() {
    return {

    };
  },
  created() {
    this.verifyEmail();
  },
  methods: {
    verifyEmail() {
      coreServices.validateEmail(this.$route.params.trialId, this.$route.params.userId);
    },
  },
};
</script>

<style scoped>
.theme--light.v-application {
  background-image: linear-gradient(45deg, #4472C4 0%, #4472C4 71%, #4472C4 150%);
}
</style>
