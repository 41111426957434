<template>
  <v-app id="inspire">
    <v-container>
      <v-row>
        <v-col cols="12" sm="10" md="10" lg="10" xl="10" class="mx-auto">
          <div class='c'>
            <div class='_404'>404</div>
            <hr>
            <div class='_1'>THE PAGE</div>
            <div class='_2'>WAS NOT FOUND</div>
            <img
              class="img-not-found"
              src="../assets/not_found_image.png"
              alt="img-not-found">
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
export default {
  name: 'NotFound',
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>
<style scoped>
.theme--light.v-application {
  background-image: linear-gradient(45deg, #4472C4 0%, #4472C4 71%, #4472C4 150%);
}
.c{
    text-align: center;
    display: block;
    position: relative;
    margin:100px auto;
}
._404{
    font-size: 200px;
    position: relative;
    display: inline-block;
    z-index: 2;
    height: 250px;
    letter-spacing: 15px;
    color: white;
}
._1{
    margin-top: 20px;
    text-align:center;
    display:block;
    position:relative;
    letter-spacing: 12px;
    font-size: 4em;
    line-height: 80%;
    color: white;
}
._2{
    text-align:center;
    display:block;
    position: relative;
    font-size: 20px;
    color: white;
}
hr{
    padding: 0;
    border: none;
    border-top: 5px solid black;
    text-align: center;
    margin: 0px auto;
    width: 420px;
    height:10px;
    z-index: -10;
}
.img-not-found{
    width: 300px;
    height: 300px;
    margin-top: 20px;
}
</style>
