<template lang="">
  <div class="content">
    <v-app id="inspire">
      <transition name="fade">
        <div v-if="isLoading" class="loading-overlay">
          <v-row cols="12" justify="center" align="center">
            <img src="../assets/images/patients-up-10.png" alt="Loading" class="loading-image" />
          </v-row>
          <v-row cols="12">
            <v-progress-circular
              indeterminate
              class="spin-color"
              size="75"
              width="7"
            />
          </v-row>
        </div>
      </transition>
      <v-row>
        <v-col cols="12" sm="10" md="10" lg="10" xl="10" class="cookie-patient-main">
          <div id="cookieModal" class="cookie-modal">
            <div class="cookie-modal-content">
              <h3 class="cookie-modal-title">{{ $t('cookie-title') }}</h3><br /><br />
              <p class="cookie-modal-text">{{ $t('cookie-msg') }}</p>
              <v-btn
                x-large
                class="cookie-modal-button"
                color="success"
                @click="cookieAccepted()">
                {{ $t('cookie-accept') }}
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="mx-auto">
          <div v-if="data.step === 1">
            <transition name="fade">
              <trial-information @update="checkStep" />
            </transition>
          </div>
          <div v-if="data.step === 2">
            <transition name="fade">
              <patient-form
                :patientData="data.patientData"
                :trialName="data.trialName"
                @update="checkPatientStep" />
            </transition>
          </div>
          <div v-if="data.step === 3 || data.step === 4">
            <transition name="fade">
              <trial-form
                @updateTrialStep="updateTrialStep"
                @trialNextStep="trialNextStep"
                @updateNotPassed="notPassed" />
            </transition>
          </div>
          <div v-if="data.step === 5">
            <transition name="fade">
              <hospital-form
                :hospitals="data.hospitals"
                @update="checkHospitalSelected" />
            </transition>
          </div>
          <div v-if="data.step === 6 && sendVerificationEmail()">
            <transition name="fade">
              <v-row>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="register-finished">
                  <div id="finishModal" class="finish-modal">
                    <div class="modal-finish-content">
                      <v-row>
                        <v-col class="mb-0 pb-0" cols="12" md="3" justify="left">
                          <v-img
                            class="modal-finish-logo mb-0 pb-0"
                            :src="require('../assets/images/patient_up_logo.png')"
                            height="7vh"
                            :contain="true" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <hr class="gradient-line" />
                      </v-row>
                      <v-row>
                        <h3 class="modal-finish-title">
                          {{ $t('finish-screen.register-completed-msg') }}
                        </h3>
                      </v-row>
                      <v-row>
                        <v-col cols="12" lg="8">
                          <v-row>
                            <div class="modal-finish-text">
                              <p> {{ $t('finish-screen.msg') }} </p>
                              <p class="email-msg-text">{{ $t('finish-screen.email-msg') }}</p>
                              <p><b>{{ $t('finish-screen.note-msg') }}</b></p>
                            </div>
                          </v-row>
                        </v-col>
                        <v-col cols="12" lg="4">
                          <v-img
                            class="modal-finish-image"
                            :src="require('../assets/images/fiesta.png')"
                            height="30vh"
                            :contain="true" />
                        </v-col>
                      </v-row>
                      <v-row class="mb-5" justify="center">
                        <v-btn
                          class="modal-finish-button"
                          x-large
                          @click="endApp()">
                          {{ $t('finish-screen.button-finish') }}
                        </v-btn>
                      </v-row>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </transition>
            <v-row>
              <v-col cols="12" md="6">
                <div class="responsive-image" />
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <div>
          <div style="position:absolute; opacity:0.85; font-size:0.9em; bottom:0; right:20px;">
            <p>
              <strong>Version: </strong>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    :class="formSubmitted && !instrRd ? 'highlight-red-text'
                      : 'trial-a inline-element'"
                    target="_blank"
                    :href="instructions"
                    @click.stop
                    v-on="on"
                    rel="noopener noreferrer"
                  >
                    {{ version }}
                  </a>
                </template>
                {{ sha }}
              </v-tooltip>
            </p>
          </div>
        </div>
      </v-row>
    </v-app>
  </div>
</template>
<script>
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics';
import TrialInformation from './TrialInformation.vue';
import PatientForm from './PatientForm.vue';
import TrialForm from './TrialForm.vue';
import HospitalForm from './HospitalForm.vue';
import { trialServices } from '../services/trialService';
import getFirebaseConfig from '../utils/firebaseConfig';
import { APP_VERSION } from '../envConfig';

export default {
  name: 'MainForm',
  components: {
    TrialInformation,
    PatientForm,
    TrialForm,
    HospitalForm,
  },
  data() {
    return {
      data: {
        step: 1,
        patientData: {},
        hospitals: [],
        trialName: '',
        dialog: true,
        doctorInfo: null,
      },
      version_sha: APP_VERSION,
      version: '',
      sha: 'test',
    };
  },
  created() {
    this.addEvent('trial_id', this.$route.params.trialId);
    console.log(this.version_sha);
    this.extractVersionAndSha();
    // this.dialog = true;
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoadingImages || this.$store.state.isLoadingData;
    },
  },
  methods: {
    async addEvent(eventName, passName) {
      if (await isSupported()) {
        const app = initializeApp(getFirebaseConfig());
        const analytics = getAnalytics(app);
        logEvent(analytics, eventName, { name: eventName, arguments: passName });
      }
    },
    extractVersionAndSha() {
      const [version, sha] = this.version_sha.split(' ');
      this.version = version;
      this.sha = sha ? sha.replace('(', '').replace(')', '') : '';
    },
    showCookieModal() {
      const modal = this.$refs.cookieModal;
      const span = this.$refs.close;
      if (span) {
        span.onclick = this.closeModal;
        span.addEventListener('keyup', (event) => {
          if (event.key === 'Enter') {
            this.closeModal();
          }
        });
      }
      // span.onclick = function () {
      //   modal.style.display = 'none';
      //   // this.$matomo.disableCookies = false;
      //   // eslint-disable-next-line
      //   // window._paq.push('disableCookies', false);
      //   // this.$matomo.disableCookies();
      // };
      if (modal) {
        // eslint-disable-next-line
        window.onclick = function (event) {
          if (event.target === modal) {
            modal.style.display = 'none';
          }
        };
      }
    },
    cookieAccepted() {
      const modal = document.getElementById('cookieModal');
      modal.style.display = 'none';
    },
    // cookieDenied() {
    //   this.$matomo.disableCookies();
    //   const modal = document.getElementById('myModal');
    //   modal.style.display = 'none';
    // },
    checkStep(neWStep) {
      this.data.step = neWStep.step;
      this.data.trialName = neWStep.trialName;
    },
    checkPatientStep(newStep) {
      this.data.step = newStep.step;
      this.data.patientData = newStep.patientData;
    },
    updateTrialStep(newStep) {
      if (newStep.selectedAnswers) {
        this.data.hospitals = newStep.hospitals;
        this.data.patientData.questions = newStep.questions;
        this.data.patientData.question_answered = newStep.selectedAnswers;
        this.data.totalValues = this.data.patientData.question_answered.length;
      }
      this.data.step = newStep.step;
    },
    trialNextStep(newStep) {
      this.data.step = newStep.step;
      this.addEvent('form_passed', 'Patient accepted');
    },
    notPassed() {
      // add document with anonymous information to the new collection
      trialServices.saveRejectedTrialInformation(
        this.data.patientData,
      );
      this.addEvent('form_not_passed', 'Patient rejected');
      return true;
    },
    checkHospitalSelected(finishStep) {
      this.data.step = finishStep.step;
      this.data.patientData.hospital_selected = finishStep.hospitalSelected;
    },
    sendVerificationEmail() {
      trialServices.saveTrialInformation(this.data.patientData);
      return true;
    },
    endApp() {
      window.location.reload();
    },
    
  },
};
</script>
<style scoped>
.email-msg-text {
  color: #33CCCC;
  font-size: 1.1em;
  font-weight: bold;
}

.responsive-image {
  top: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  left: -5%;
  height: 100vh;
  width: 120%;
  overflow: hidden !important;
  background: linear-gradient(rgba(18, 197, 224, 0.4), rgba(18, 197, 224, 0.4)),
    url('../assets/images/hospital.png') no-repeat center center fixed;
  background-size: cover;
}

.loading-overlay {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fefefe;
}

.loading-image {
  width: 30%;
  max-width: 1000px;
  object-fit: cover;
}

.spin-color {
  height: 10vh !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 1;
}

.fade-leave,
.fade-enter-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.spin-color {
  top: -10vh;
  color: var(--highlightedPU);
}

.cookie-patient-main {
  width: 90% !important;
  margin: 0;
  padding: 0;
}

.theme--light.v-application {
  background-color: #fff;
}

/* Cookie Modal Content */
.cookie-modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(18, 197, 224, 0.4);
}

.cookie-modal-title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Roboto-Regular';
  font-size: 1.4em;
  background: linear-gradient(90deg, #13c5e0, #2e90e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cookie-modal-content {
  background-color: #fefefe;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #888;
  width: 55%;
  text-align: center;
  border-radius: 15px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.7);
}

.cookie-modal-text {
  width: 90%;
  margin: auto;
  margin-top: -30px;
  margin-bottom: 40px;
  opacity: 1;
  font-family: 'Roboto-Bold';
  font-size: 1em;
  background: none !important;
  color: var(--typographyPU) !important;
  text-align: left;
  line-height: 1.5em;
}

.cookie-modal-content button {
  width: 135px;
  height: 45px !important;
  font-family: 'Roboto-Bold';
  font-size: 0.9em;
  border-radius: 23px;
  background: none !important;
  border: solid var(--highlightedPU) 3px !important;
  color: var(--highlightedPU) !important;
  opacity: 1;
  box-shadow: none !important;
  margin-bottom: 15px;
}

.cookie-close {
  margin-top: -12px;
  margin-bottom: 0;
  color: var(--typographyPU);
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.cookie-close:hover,
.cookie-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* End Cookie Modal Content */

/* Register Completed */
.register-finished {
  position: absolute;
  z-index: 99;
  text-align: left;
}

.finish-modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-finish-content {
  background-color: #fefefe;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  border-radius: 15px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.7);
}

.modal-finish-logo {
  margin-top: 2vh;
  margin-left: -5%;
  padding: 0;
}

.gradient-line {
  margin: auto;
  width: 90%;
  height: 3px;
  background: linear-gradient(90deg, #13c5e0, #2e90e0);
}

.modal-finish-title {
  margin-top: 3vh;
  margin-left: 7%;
  font-family: 'Roboto-Regular';
  font-size: 1.6em;
  color: var(--highlightedPU);
}

.modal-finish-text {
  width: 90%;
  height: 35vh;
  margin-top: 3vh;
  margin-bottom: 45px;
  margin-left: 9%;
  opacity: 1;
  font-family: 'Roboto-Bold';
  font-size: 1em;
  background: none !important;
  color: var(--typographyPU) !important;
  text-align: left;
}

.modal-finish-image {
  margin: auto;
}

.modal-finish-button {
  margin-top: 5vh;
  margin-bottom: 2vh;
  text-align: center;
  width: 220px;
  height: 45px !important;
  font-family: 'Roboto-Bold';
  font-size: 0.9em;
  border-radius: 23px;
  background: none !important;
  border: solid var(--highlightedPU) 3px !important;
  color: var(--highlightedPU) !important;
  opacity: 1;
  box-shadow: none !important;
}

.version-container {
  position: absolute;
  opacity: 0.85;
  font-size: 0.9em;
  bottom: 0;
  right: 20px;
}

/* End Register Completed */

@media (min-width: 2561px) {
  .cookie-modal {
    padding-top: 10vh;
  }

  .cookie-modal-content {
    max-width: 840px;
  }

  .cookie-modal-title {
    padding-top: 2vh;
    padding-bottom: 1vh;
    font-size: 1.4em;
  }

  .cookie-modal-text {
    font-size: 1.1em;
    line-height: 1.2em;
  }

  .cookie-modal-button {
    font-size: 1.1em;
  }

  .modal-finish-content {
    width: 55%;
  }

  .modal-finish-logo {
    height: 10vh !important;
    margin-top: 0;
    margin-left: 7%;
    padding: 0;
  }

  .modal-finish-title {
    margin-top: 3vh;
    margin-left: 5%;
    width: 100%;
    text-align: left;
    font-size: 1.4em;
  }

  .modal-finish-text {
    width: 90%;
    height: 10vh;
    font-size: 1.1em;
    margin-top: 3vh;
    margin-bottom: 0;
    margin-left: 8%;
    margin-right: 8%;
  }

  .modal-finish-image {
    top: 0;
    margin-right: 10%;
    display: block;
  }

  .modal-finish-button {
    margin-top: 14vh;
    margin-bottom: 2vh;
    width: 185px;
    height: 45px !important;
    font-size: 1.1em;
    border-radius: 23px;
  }
}

@media (min-width: 1441px) and (max-width: 2561px) {
  .cookie-modal {
    padding-top: 10vh;
  }

  .cookie-modal-content {
    max-width: 840px;
  }

  .cookie-modal-title {
    padding-top: 2vh;
    padding-bottom: 1vh;
    font-size: 1.4em;
  }

  .cookie-modal-text {
    font-size: 1.1em;
    line-height: 1.2em;
  }

  .cookie-modal-button {
    font-size: 1em;
  }

  .finish-modal {
    padding-top: 3vh;
  }

  .modal-finish-content {
    width: 70%;
  }

  .modal-finish-logo {
    height: 10vh !important;
    margin-top: 0;
    margin-left: 7%;
    padding: 0;
  }

  .modal-finish-title {
    margin-top: 3vh;
    margin-left: 5%;
    width: 100%;
    text-align: left;
    font-size: 1.4em;
  }

  .modal-finish-text {
    width: 90%;
    height: 10vh;
    font-size: 1.1em;
    margin-top: 3vh;
    margin-bottom: 0;
    margin-left: 8%;
    margin-right: 8%;
  }

  .modal-finish-image {
    top: 0;
    margin-right: 10%;
    display: block;
  }

  .modal-finish-button {
    margin-top: 17vh;
    margin-bottom: 2vh;
    width: 185px;
    height: 45px !important;
    font-size: 1.1em;
    border-radius: 23px;
  }
}

@media (min-width: 1365px) and (max-width: 1440px) {
  .cookie-modal {
    padding-top: 10vh;
  }

  .cookie-modal-content {
    width: 55%;
  }

  .cookie-modal-title {
    padding-top: 2vh;
    padding-bottom: 1vh;
    font-size: 1.4em;
  }

  .cookie-modal-text {
    font-size: 1.1em;
    line-height: 1.2em;
  }

  .cookie-modal-button {
    font-size: 1.1em;
  }

  .modal-finish-content {
    width: 70%;
  }

  .modal-finish-logo {
    height: 10vh !important;
    margin-top: 0;
    margin-left: 7%;
    padding: 0;
  }

  .modal-finish-title {
    margin-top: 3vh;
    margin-left: 5%;
    width: 100%;
    text-align: left;
    font-size: 1.4em;
  }

  .modal-finish-text {
    width: 90%;
    height: 10vh;
    font-size: 1.1em;
    margin-top: 3vh;
    margin-bottom: 0;
    margin-left: 8%;
    margin-right: 8%;
  }

  .modal-finish-image {
    top: 0;
    margin-right: 10%;
    display: block;
  }

  .modal-finish-button {
    margin-top: 14vh;
    margin-bottom: 2vh;
    width: 185px;
    height: 45px !important;
    font-size: 1.1em;
    border-radius: 23px;
  }
}

@media (min-width: 1281px) and (max-width: 1365px) {
  .cookie-modal {
    padding-top: 10vh;
  }

  .cookie-modal-content {
    width: 55%;
  }

  .cookie-modal-title {
    padding-top: 2vh;
    padding-bottom: 1vh;
    font-size: 1.4em;
  }

  .cookie-modal-text {
    font-size: 1.2m;
    line-height: 1.2em;
  }

  .cookie-modal-button {
    font-size: 1.1em;
  }

  .finish-modal {
    padding-top: 3vh;
  }

  .modal-finish-content {
    width: 80%;
  }

  .modal-finish-logo {
    height: 10vh !important;
    margin-top: 0;
    margin-left: 7%;
    padding: 0;
  }

  .modal-finish-title {
    margin-top: 3vh;
    margin-left: 5%;
    width: 100%;
    text-align: left;
    font-size: 1.4em;
  }

  .modal-finish-text {
    width: 90%;
    height: 10vh;
    font-size: 1.1em;
    margin-top: 3vh;
    margin-bottom: 0;
    margin-left: 8%;
    margin-right: 8%;
  }

  .modal-finish-image {
    top: 0;
    margin-right: 10%;
    display: block;
  }

  .modal-finish-button {
    margin-top: 14vh;
    margin-bottom: 2vh;
    width: 185px;
    height: 45px !important;
    font-size: 1.1em;
    border-radius: 23px;
  }
}

@media (min-width: 1180px) and (max-width: 1280px) {
  .cookie-modal {
    padding-top: 10vh;
  }

  .cookie-modal-content {
    width: 55%;
  }

  .cookie-modal-title {
    padding-top: 2vh;
    padding-bottom: 1vh;
    font-size: 1.4em;
  }

  .cookie-modal-text {
    font-size: 1.1em;
    line-height: 1.2em;
  }

  .cookie-modal-button {
    font-size: 1.1em;
  }

  .modal-finish-content {
    width: 70%;
  }

  .modal-finish-image {
    display: none;
  }

  .modal-finish-logo {
    height: 10vh !important;
    margin-top: 0;
    margin-left: 7%;
    padding: 0;
  }

  .modal-finish-title {
    margin-top: 3vh;
    margin-left: 5%;
    width: 100%;
    text-align: left;
    font-size: 1.4em;
  }

  .modal-finish-text {
    width: 90%;
    height: 50vh;
    font-size: 1.1em;
    margin-top: 3vh;
    margin-bottom: 0;
    margin-left: 8%;
    margin-right: 8%;
  }

  .modal-finish-image {
    display: none;
  }

  .modal-finish-button {
    margin-top: 10vh;
    margin-bottom: 2vh;
    width: 185px;
    height: 45px !important;
    font-size: 1.1em;
    border-radius: 23px;
  }
}

@media (min-width: 1025px) and (max-width: 1179px) {
  .cookie-modal {
    padding-top: 10vh;
  }

  .cookie-modal-content {
    width: 65%;
  }

  .cookie-modal-title {
    padding-top: 2vh;
    padding-bottom: 1vh;
    font-size: 1.4em;
  }

  .cookie-modal-text {
    font-size: 1.1em;
    line-height: 1.2em;
  }

  .cookie-modal-button {
    font-size: 1.1em;
  }

  .modal-finish-content {
    width: 70%;
  }

  .modal-finish-logo {
    height: 12vh !important;
    width: 120%;
    margin-top: 0;
    margin-left: 7%;
    padding: 0;
  }

  .modal-finish-title {
    margin-top: 3vh;
    margin-left: 7%;
    width: 100%;
    text-align: left;
    font-size: 1.4em;
  }

  .modal-finish-text {
    width: 90%;
    height: 25vh;
    font-size: 1.1em;
    margin-top: 3vh;
    margin-bottom: 0;
    margin-left: 8%;
    margin-right: 8%;
  }

  .modal-finish-image {
    display: none;
  }

  .modal-finish-button {
    margin-top: 14vh;
    margin-bottom: 2vh;
    width: 185px;
    height: 45px !important;
    font-size: 1.1em;
    border-radius: 23px;
  }
}

@media (min-width: 821px) and (max-width: 1024px) {
  .cookie-modal {
    padding-top: 10vh;
  }

  .cookie-modal-content {
    width: 85%;
  }

  .cookie-modal-title {
    padding-top: 2vh;
    padding-bottom: 1vh;
    font-size: 1.4em;
  }

  .cookie-modal-text {
    font-size: 1.1em;
    line-height: 1.2em;
  }

  .cookie-modal-button {
    font-size: 1.1em;
  }

  .modal-finish-content {
    width: 70%;
  }

  .modal-finish-logo {
    height: 8vh !important;
    width: 120%;
    margin-top: 0;
    margin-left: 0;
    padding: 0;
  }

  .modal-finish-title {
    margin-top: 3vh;
    margin-left: 10%;
    width: 100%;
    text-align: left;
    font-size: 1.4em;
  }

  .modal-finish-text {
    width: 90%;
    height: 30vh;
    font-size: 1.1em;
    margin-top: 3vh;
    margin-bottom: 0;
    margin-left: 8%;
    margin-right: 8%;
  }

  .modal-finish-image {
    display: none;
  }

  .modal-finish-button {
    margin-top: 14vh;
    margin-bottom: 2vh;
    width: 185px;
    height: 45px !important;
    font-size: 1.1em;
    border-radius: 23px;
  }
}

@media (min-width: 769px) and (max-width: 820px) {
  .cookie-modal {
    padding-top: 10vh;
  }

  .cookie-modal-content {
    width: 85%;
  }

  .cookie-modal-title {
    padding-top: 2vh;
    padding-bottom: 1vh;
    font-size: 1.4em;
  }

  .cookie-modal-text {
    font-size: 1.1em;
    line-height: 1.2em;
  }

  .cookie-modal-button {
    font-size: 1.1em;
  }

  .modal-finish-content {
    width: 70%;
  }

  .modal-finish-logo {
    height: 8vh !important;
    width: 120%;
    margin-top: 0;
    margin-left: 0;
    padding: 0;
  }

  .modal-finish-title {
    margin-top: 3vh;
    margin-left: 10%;
    width: 100%;
    text-align: left;
    font-size: 1.4em;
  }

  .modal-finish-text {
    width: 90%;
    height: 35vh;
    font-size: 1.1em;
    margin-top: 3vh;
    margin-bottom: 0;
    margin-left: 8%;
    margin-right: 8%;
  }

  .modal-finish-image {
    display: none;
  }

  .modal-finish-button {
    margin-top: 14vh;
    margin-bottom: 2vh;
    width: 185px;
    height: 45px !important;
    font-size: 1.1em;
    border-radius: 23px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .cookie-modal {
    padding-top: 130px;
  }

  .cookie-modal-content {
    width: 85%;
  }

  .cookie-modal-title {
    padding-top: 2vh;
    padding-bottom: 1vh;
    font-size: 1.4em;
  }

  .cookie-modal-text {
    font-size: 1.1em;
    line-height: 1.2em;
  }

  .cookie-modal-button {
    font-size: 1.1em;
  }

  .finish-modal {
    padding-top: 20px;
  }

  .modal-finish-content {
    width: 95%;
  }

  .modal-finish-logo {
    height: 8vh !important;
    width: 120%;
    margin-top: 0;
    margin-left: 0;
    padding: 0;
  }

  .modal-finish-title {
    margin-top: 3vh;
    margin-left: 10%;
    width: 100%;
    text-align: left;
    font-size: 1.4em;
  }

  .modal-finish-text {
    width: 90%;
    height: 50vh;
    font-size: 1.1em;
    margin-top: 3vh;
    margin-bottom: 0;
    margin-left: 8%;
    margin-right: 8%;
  }

  .modal-finish-image {
    display: none;
  }

  .modal-finish-button {
    margin-top: 14vh;
    margin-bottom: 2vh;
    width: 185px;
    height: 45px !important;
    font-size: 1.1em;
    border-radius: 23px;
  }
}

@media (min-width: 380px) and (max-width: 480px) {
  .cookie-modal {
    padding-top: 130px;
  }

  .cookie-modal-content {
    width: 85%;
  }

  .cookie-modal-title {
    padding-top: 2vh;
    padding-bottom: 1vh;
    font-size: 1.4em;
  }

  .cookie-modal-text {
    font-size: 1.1em;
    line-height: 1.2em;
  }

  .cookie-modal-button {
    font-size: 1.1em;
  }

  .finish-modal {
    padding-top: 30px;
  }

  .modal-finish-content {
    width: 95%;
  }

  .modal-finish-logo {
    height: 8vh !important;
    width: 120%;
    margin-top: 0;
    margin-left: 0;
    padding: 0;
  }

  .modal-finish-title {
    margin-top: 3vh;
    margin-left: 10%;
    width: 100%;
    text-align: left;
    font-size: 1.4em;
  }

  .modal-finish-text {
    width: 90%;
    height: 65vh;
    font-size: 1.1em;
    margin-top: 3vh;
    margin-bottom: 0;
    margin-left: 8%;
    margin-right: 8%;
  }

  .modal-finish-image {
    display: none;
  }

  .modal-finish-button {
    margin-top: 14vh;
    margin-bottom: 2vh;
    width: 185px;
    height: 45px !important;
    font-size: 1.1em;
    border-radius: 23px;
  }
}

@media (min-width: 281px) and (max-width: 379px) {
  .cookie-modal {
    padding-top: 50px;
  }

  .cookie-modal-content {
    width: 85%;
  }

  .cookie-modal-text {
    font-size: 1em;
    line-height: 1.2em;
  }

  .cookie-modal-title {
    padding-top: 3vh;
    padding-bottom: 2vh;
    font-size: 1.4em;
  }

  .cookie-modal-button {
    font-size: 1.1em;
  }

  .register-finished {
    text-align: center;
  }

  .finish-modal {
    padding-top: 30px;
  }

  .modal-finish-content {
    width: 95%;
  }

  .modal-finish-logo {
    height: 8vh !important;
    width: 120%;
    margin-top: 0;
    margin-left: 0;
    padding: 0;
  }

  .modal-finish-title {
    margin-top: 3vh;
    margin-left: 10%;
    width: 100%;
    text-align: left;
    font-size: 1.4em;
  }

  .modal-finish-text {
    width: 90%;
    height: 65vh;
    font-size: 0.9em !important;
    margin-top: 3vh;
    margin-bottom: 0;
    margin-left: 8%;
    margin-right: 8%;
  }

  .modal-finish-image {
    display: none;
  }

  .modal-finish-button {
    margin-top: 14vh;
    margin-bottom: 2vh;
    width: 185px;
    height: 45px !important;
    font-size: 1.1em;
    border-radius: 23px;
  }
}

@media (max-width: 280px) {
  .cookie-modal {
    padding-top: 50px;
  }

  .cookie-modal-content {
    width: 90%;
  }

  .cookie-modal-title {
    padding-top: 3vh;
    padding-bottom: 2vh;
    font-size: 1.4em;
  }

  .cookie-modal-text {
    font-size: 1.1em;
    line-height: 1.2em;
  }

  .cookie-modal-button {
    font-size: 1.1em;
  }

  .register-finished {
    text-align: center;
  }

  .finish-modal {
    padding-top: 30px;
  }

  .modal-finish-content {
    width: 95%;
  }

  .modal-finish-logo {
    height: 8vh !important;
    width: 120%;
    margin-top: 0;
    margin-left: 0;
    padding: 0;
  }

  .modal-finish-title {
    margin-top: 3vh;
    margin-left: 10%;
    width: 100%;
    text-align: left;
    font-size: 1.4em;
  }

  .modal-finish-text {
    width: 90%;
    height: 95vh;
    font-size: 1.1em;
    margin-top: 3vh;
    margin-bottom: 0;
    margin-left: 8%;
    margin-right: 8%;
  }

  .modal-finish-image {
    display: none;
  }

  .modal-finish-button {
    margin-top: 14vh;
    margin-bottom: 2vh;
    width: 185px;
    height: 45px !important;
    font-size: 1.1em;
    border-radius: 23px;
  }
}

@media screen and (min-resolution: 2dppx) and (orientation: portrait) and (max-width: 1250px) {
  .loading-image {
    height: 12vh !important;
    width: 90%;
  }

  .spin-color {
    margin-top: -5vh;
    height: 6vh !important;
  }

  .cookie-modal {
    padding-top: 10vh;
  }

  .cookie-modal-content {
    width: 85%;
  }

  .cookie-modal-title {
    padding-top: 2vh;
    padding-bottom: 1vh;
    font-size: 1.4em;
  }

  .cookie-modal-text {
    font-size: 1.1em;
    line-height: 1.2em;
  }

  .cookie-modal-button {
    font-size: 1.1em;
  }

  .finish-modal {
    padding-top: 30px;
  }

  .modal-finish-content {
    width: 95%;
  }

  .modal-finish-logo {
    height: 8vh !important;
    width: 120%;
    margin-top: 0;
    margin-left: 0;
    padding: 0;
  }

  .modal-finish-title {
    margin-top: 3vh;
    margin-left: 10%;
    width: 100%;
    text-align: left;
    font-size: 1.4em;
  }

  .modal-finish-text {
    width: 90%;
    height: 65vh;
    font-size: 1.05em;
    margin-top: 3vh;
    margin-bottom: 0;
    margin-left: 8%;
    margin-right: 8%;
  }

  .modal-finish-image {
    display: none;
  }

  .modal-finish-button {
    margin-top: 0 !important;
    margin-bottom: 2vh;
    width: 35% !important;
    height: 45px !important;
    font-size: 0.9em !important;
    border-radius: 23px;
  }
}

@media screen and (min-resolution: 2dppx) and (orientation: portrait) and (max-width: 375px) {
  .loading-image {
    height: 12vh !important;
    width: 90%;
  }

  .spin-color {
    margin-top: -5vh;
    height: 6vh !important;
  }

  .cookie-modal {
    padding-top: 10vh;
  }

  .cookie-modal-content {
    width: 85%;
  }

  .cookie-modal-title {
    padding-top: 2vh;
    padding-bottom: 1vh;
    font-size: 1.4em;
  }

  .cookie-modal-text {
    font-size: 1.1em;
    line-height: 1.2em;
  }

  .cookie-modal-button {
    font-size: 1.1em;
  }

  .finish-modal {
    padding-top: 30px;
  }

  .modal-finish-content {
    width: 95%;
  }

  .modal-finish-logo {
    height: 8vh !important;
    width: 120%;
    margin-top: 0;
    margin-left: 0;
    padding: 0;
  }

  .modal-finish-title {
    margin-top: 3vh;
    margin-left: 10%;
    width: 100%;
    text-align: left;
    font-size: 1.4em;
  }

  .modal-finish-text {
    width: 90%;
    height: 80vh;
    font-size: 1.05em;
    margin-top: 3vh;
    margin-bottom: 0;
    margin-left: 8%;
    margin-right: 8%;
  }

  .modal-finish-image {
    display: none;
  }

  .modal-finish-button {
    margin-top: 14vh;
    margin-bottom: 2vh;
    width: 35% !important;
    height: 45px !important;
    font-size: 0.9em !important;
    border-radius: 23px;
  }
}
</style>
